<!--
   Created by Terence on 2023/2/21 - 16:34
-->
<template>
  <div class="author section">
    <div class="section-title">作为拥有者我可以</div>
    <div class="actions">
      <content-wrp>
        <div class="actions-item">
          <span class="actions-item-label">下载无水印的作品原图</span>
          <DuButton type="recommend" size="small" @click="handleShowImgs"
            >去下载</DuButton
          >
        </div>
      </content-wrp>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
import { showImagePreview } from "vant";
import { DuButton } from "dangoui";
import ContentWrp from "@/components/ContentWrp/index.vue";
import BidApi from "@/apis/bid";
import { REQUEST_OK } from "@/constant/network";

const props = defineProps({
  originImages: {
    type: Array,
    required: true,
    default: () => [],
  },
  bondPrice: {
    type: Number,
    required: true,
  },
});

const handleShowImgs = () => {
  const tempImgs = props.originImages.map(
    // (item) => item.url + "!mfit_w750_h750_jpg"
    (item) => item.url
  );
  console.log("props.originImages", props.originImages);
  console.log("tempImgs", tempImgs);
  showImagePreview({
    images: tempImgs,
    className: "downloadable-img-wrp",
  });
};
</script>

<style lang="scss" scoped>
.actions {
  margin-top: 32rpx;
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-label {
      font-weight: 400;
      font-size: 24rpx;
      line-height: 36rpx;
      color: #000000;
    }
  }
}
</style>

<!--v-model	min max step-->
