<!--
   Created by Terence on 2024/3/7 - 17:13
-->
<template>
  <div class="binfo">
    <!--    <div class="binfo-imgs">-->
    <!--      <van-swipe class="sp-wrp" :autoplay="0" indicator-color="#7247DC">-->
    <!--        <van-swipe-item-->
    <!--          v-for="(item, index) in spDetailData?.attachments"-->
    <!--          :key="index"-->
    <!--          class="sp-item"-->
    <!--        >-->
    <!--          <img class="sp-item-img" :src="item + '!mfit_w750_h750_jpg'" />-->
    <!--        </van-swipe-item>-->
    <!--      </van-swipe>-->
    <!--    </div>-->

    <div class="binfo-covers">
      <div
        v-for="(item, index) in spDetailData?.attachmentsV2"
        :key="index"
        class="binfo-covers-item"
      >
        <img
          class="binfo-covers-item-img"
          :src="item?.url + '!mfit_w750_h750_jpg'"
        />
        <div class="token-entry" v-if="item.relatedType === 'TOKEN'">
          <!--          arrow-right-->
          <DuButton
            @click="toTokenPage(item.relatedId)"
            size="mini"
            color="secondary"
            >查看作品&nbsp;<ArrowRight type="white"
          /></DuButton>
        </div>
      </div>
    </div>

    <!--    <div-->
    <!--      class="binfo-imgs-token"-->
    <!--      v-for="(item, index) in relatedTokenListInfo"-->
    <!--      :key="index"-->
    <!--    >-->
    <!--      &lt;!&ndash;      {{item.id}}&ndash;&gt;-->
    <!--      &lt;!&ndash;      <img class="sp-item-img" :src="item?.watermaskImages?.[0]?.url + '!mfit_w750_h750_jpg'" />&ndash;&gt;-->
    <!--      &lt;!&ndash;    关联token的 token信息&ndash;&gt;-->
    <!--      <van-swipe class="sp-wrp" :autoplay="0" indicator-color="#7247DC">-->
    <!--        &lt;!&ndash;        {{item?.watermaskImages?.[0]?.url}}&ndash;&gt;-->
    <!--        <van-swipe-item class="sp-item">-->
    <!--          <img-->
    <!--            class="sp-item-img"-->
    <!--            :src="item?.watermaskImages?.[0]?.url + '!mfit_w750_h750_jpg'"-->
    <!--          />-->
    <!--        </van-swipe-item>-->
    <!--        <div class="token-entry">-->
    <!--          &lt;!&ndash;          arrow-right&ndash;&gt;-->
    <!--          <DuButton @click="toTokenPage(item.id)" size="mini" color="secondary"-->
    <!--            >查看作品&nbsp;<ArrowRight type="white"-->
    <!--          /></DuButton>-->
    <!--        </div>-->
    <!--      </van-swipe>-->
    <!--      <div class="binfo-imgs-token-info">-->
    <!--        <TokenPreviewSection-->
    <!--          :propsDetailData="item"-->
    <!--          :propsTokenId="item.id"-->
    <!--          :showDesc="false"-->
    <!--          @onInvokeApp="onInvokeApp"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="binfo-title">{{ spDetailData.name }}</div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, provide, watch, defineEmits } from "vue";
import { Swipe as VanSwipe, SwipeItem as VanSwipeItem } from "vant";
import { DuButton } from "dangoui";
import TokenPreviewSection from "./TokenPreviewSection";
// import TokenNumber from "./TokenNumber.vue";
import { useElementVisibility } from "@/hooks/useElementVisibility";
import ArrowRight from "@/components/ArrowRight/index.vue";
import { useRouter } from "vue-router";
import NftApi from "@/apis";
import { REQUEST_OK } from "@/constant/network";

const router = useRouter();

const spDetailData = inject("spDetailData");
const buyoutDetailData = inject("buyoutDetailData");
const auctionDetailData = inject("auctionDetailData");
const tokenId = inject("tokenId");
// provide("detailData", { name: 666 });

const emit = defineEmits(["onInvokeApp"]);

const relatedTokenListInfo = ref([]);

const onInvokeApp = () => {
  emit("onInvokeApp");
};

const toTokenPage = (id) => {
  router.push(`/token-detail?tokenId=${id}`);
};
</script>

<style lang="scss" scoped>
.binfo {
  position: relative;

  &-title {
    color: #0d002d;
    font-family: "PingFang SC";
    font-size: 32rpx;
    font-style: normal;
    font-weight: 500;
    line-height: 48rpx; /* 150% */
  }

  &-covers {
    margin-top: 24rpx;

    &-item {
      position: relative;
      width: 100%;
      border-radius: 32rpx;
      margin-bottom: 32rpx;
      overflow: hidden;
      &-img {
        width: 100%;
        display: block;
        aspect-ratio: 1;
        object-fit: cover;
      }
    }

    .token-entry {
      position: absolute;
      right: 32rpx;
      bottom: 32rpx;
    }
  }
}
//.binfo {
//  position: relative;
//  //padding-bottom: 32rpx;
//  //border-radius: 32rpx;
//  //background: #fff;
//  //box-shadow: 0 8rpx 40rpx 0 rgba(0, 0, 0, 0.06);
//  //overflow: hidden;
//
//  &-imgs-token {
//    position: relative;
//    margin-top: 24rpx;
//    margin-bottom: 32rpx;
//
//    background-color: white;
//    box-shadow: 0 8rpx 40rpx 0 rgba(0, 0, 0, 0.06);
//    border-radius: 32rpx;
//    overflow: hidden;
//
//    &-info {
//      padding: 32rpx;
//    }
//
//    .sp-wrp {
//      position: relative;
//    }
//
//    .sp-wrp .sp-item img {
//      border-radius: 32rpx 32rpx 0 0 !important;
//    }
//
//    .token-entry {
//      position: absolute;
//      right: 32rpx;
//      bottom: 32rpx;
//    }
//  }
//
//  &-title {
//    color: #0d002d;
//    font-family: "PingFang SC";
//    font-size: 32rpx;
//    font-style: normal;
//    font-weight: 500;
//    line-height: 48rpx; /* 150% */
//  }
//  .sp-wrp .sp-item img {
//    width: 100%;
//    display: block;
//    aspect-ratio: 1;
//    object-fit: cover;
//
//    border-radius: 32rpx;
//  }
//
//  .showact {
//    position: absolute;
//    bottom: 16rpx;
//    right: 30rpx;
//    display: flex;
//    padding: 6rpx 20rpx;
//    justify-content: center;
//    align-items: center;
//    color: var(--v-10-main-white-09, #fff);
//    font-size: 24rpx;
//    font-style: normal;
//    font-weight: 500;
//    line-height: 36rpx; /* 150% */
//    border-radius: 8rpx;
//    background: var(---button_info_color, rgba(0, 0, 0, 0.5));
//  }
//
//  .token-preview-section-wrp {
//    padding: 0 32rpx;
//  }
//}
</style>
